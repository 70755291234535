header {

  .header-row {
    padding: 6rem 0;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include desktop-block {
      padding: 0;
      padding-top: 3rem;
      padding-bottom: 6rem;
    }

    @include tablet {
      padding-bottom: 0;
    }
  }

  .logo-wrap {
    @include little-phone {
      width: 200px;
    }
  }

  .burger {
    display: none;
    z-index: 13;

    @include tablet {
      display: block;
    }

    .br {
      width: 42px;
      height: 6px;
      margin: 4px 0;
      border-radius: 5px;
      background-color: $white;
      transition: 0.5s;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

    }
  }

  .burger-open {
    .br-1 {
      transform: rotate(45deg) translateX(14px);
    }

    .br-2 {
      width: 0;
    }

    .br-3 {
      transform: rotate(-45deg) translateX(13px);
    }
  }

  .burger-menu {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    right: -100%;
    max-width: 400px;
    background-color: rgba($black, 0.8);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 12;
    transition: 0.5s;

    .header-link {
      color: $white;
      font-family: $font-regular;
      text-transform: uppercase;
      font-size: 1.8rem;
      padding: 10px 0;
    }

    @include tablet {
      display: flex;
    }
  }

  .burger-menu-open {
    right: 0;
  }

  .menu-wrap {
    @include desktop-block {
      position: absolute;
      width: 100%;
      top: 90%;
      display: flex;
      justify-content: center;
      padding-top: 0;
    }

    @include tablet {
      display: none;
    }

    .header-link {
      color: $white;
      font-family: $font-regular;
      text-transform: uppercase;
      font-size: 1.8rem;
      margin: 0 10px;
      position: relative;
      transition: 0.5s;

      &:after {
        position: absolute;
        content: "";
        height: 2px;
        width: 0%;
        top: 100%;
        left: 0;
        transition: 0.5s;
        background: linear-gradient(135deg, rgba(242, 0, 0, 1) 0%, rgba(255, 255, 255, 1) 100%);
      }

      @include desktop-little {
        padding: 0 5px;
      }


      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        color: $red;

        &:after {
          width: 100%;
        }
      }
    }

    .active-link {
      color: $red;
    }
  }

  .lang-wrap {
    display: flex;

    @include small-phone {
      position: absolute;
      width: 100%;
      top: 100%;
      justify-content: center;
      padding-top: 10px;
    }

    .lang-item {
      padding: 5px;
      margin: 0 5px;
      height: 34px;
      width: 40px;

      img {
        transition: 0.5s
      }

      &:hover {
        img {
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
}
