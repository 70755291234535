.section {
  padding-bottom: 50px;

  @include small-phone {
    padding-top: 30px;
  }

  .title-row {
    padding: 30px 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include phone {
      padding-bottom: 50px;
    }

    .title-wrap {
      color: $white;
      font-size: 4.6rem;
      text-align: center;

      @include phone {
        font-size: 3rem;
      }
    }
  }

  .content-row {
    justify-content: space-between;

    @include middle-tablet {
      flex-wrap: wrap;
      justify-content: center;
    }

    .img-wrap {
      flex: 0 0 25%;

      @include desktop-little {
        flex: 0 0 35%;
      }

      @include middle-tablet {
        flex: 0 0 60%;
      }

      @include small-phone {
        flex: 0 0 80%;
      }

      img {
        object-fit: cover;
        max-height: 500px;
        border-radius: 15px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .3);
      }
    }

    .descr-wrap {
      flex: 0 0 70%;
      color: $white;
      font-size: 2rem;
      font-family: $font-regular;



      @include desktop-little {
        flex: 0 0 60%;
      }

      @include middle-tablet {
        flex: 0 0 100%;
        padding-top: 30px;
      }

      p {
        padding: 5px 0;
        line-height: 1.4;

        &::selection {
          background-color: rgba($red, 0.8);
        }
      }

      h3 {
        font-size: 2.2rem;

        &::selection {
          background-color: rgba($red, 0.8);
        }
      }

      em {
        font-style: italic;

        &::selection {
          background-color: rgba($red, 0.8);
        }
      }

      strong {
        font-size: 2.4rem;

        &::selection {
          background-color: rgba($red, 0.8);
        }
      }

      ul {
        padding: 15px 0;
        padding-left: 20px;

        &::selection {
          background-color: rgba($red, 0.8);
        }

        p {
          padding-left: 50px;

          &::selection {
            background-color: rgba($red, 0.8);
          }
        }

        li {
          line-height: 1.4;

          &::selection {
            background-color: rgba($red, 0.8);
          }
        }
      }
    }
  }

  .concert-wrap {
    .items-block {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .item {
        flex: 0 0 auto;
        padding: 20px;
        max-width: 100%;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;

        img {
          border-radius: 15px;
          max-height: 500px;
          width: auto;
          max-width: 100%;
          object-fit: cover;
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, .3);

          @include small-phone {
            max-height: 350px;
          }
        }

        iframe {
          height: 100%;
          width: 100%;
          min-height: 350px;

          @include desktop-block {
            min-height: 300px;
          }
        }
      }

      .video-item {
        border-radius: 15px;
        background-color: $white;
        flex: 0 0 40%;
        min-width: 500px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .3);

        @include desktop-block {
          min-width: 45%;
        }

        @include tablet {
          flex: 0 0 100%;
          min-width: unset;
        }

        .video-title {
          align-self: center;
          font-size: 2rem;
          font-family: $font-regular;
          padding: 20px 0;
          text-align: center;
        }

        .video-desc {
          align-self: center;
          font-size: 1.6rem;
          padding-top: 0;

        }
      }
    }
  }

  .contacts-row {
    display: flex;
    justify-content: space-between;

    @include middle-tablet {
      flex-direction: column;
    }

    .col {
      flex: 0 0 50%;
    }

    .col-right {
      padding: 0 40px;

      @include desktop-block {
        padding: 0 15px;
      }

      @include little-phone {
        padding: 0 5px;
      }

      .content-wrap {
        background-color: rgba($black, 0.7);
        border-radius: 6px;
        max-width: 70%;
        margin: 0 auto;

        @include desktop-little {
          max-width: 95%;
        }

        @include desktop-block {
          max-width: unset;
        }
      }

      form {
        padding: 15px;
        display: flex;
        flex-direction: column;

        label {
          color: $white;
          font-size: 2rem;
          padding: 10px 0;
        }

        .success {
          background-color: rgba(46, 139, 87, 0.5);
          text-align: center;
          border-radius: 5px;
          margin-top: 10px;
        }

        button {
          margin-top: 10px;
          max-width: 200px;
          align-self: center;
          border: none;
          font-size: 2rem;
          background-color: $gray-3;
          color: $white;
          padding: 10px 15px;
          letter-spacing: 2px;
          border-radius: 10px;
          cursor: pointer;
          text-transform: uppercase;
        }

        input {
          border: none;
          font-size: 2rem;
          padding: 10px 15px;
          border-radius: 5px;
          background-color: rgba($white, 0.7);
        }

        textarea {
          background-color: rgba($white, 0.7);
          border: none;
          font-size: 2rem;
          padding: 10px 15px;
          font-family: $font-regular;
          border-radius: 5px;
          height: 120px;

          @include little-phone {
            max-height: 200px;
          }
        }
      }
    }

    .col-left {
      .content-wrap {
        display: flex;
        flex-direction: column;
        color: $white;
        font-size: 3rem;

        @include middle-tablet {
          align-items: center;
          padding-bottom: 20px;
        }

        p {
          padding: 10px 0;

          @include small-phone {
            font-size: 2.4rem;
          }
        }
      }
    }
  }

}
