//Typography
$font-family: Roboto-Light;
$font-medium: Roboto-Medium;
$font-bold: Roboto-Bold;
$font-regular: Roboto-Regular;

* {
  box-sizing: border-box;
}

html {
  font-family: $font-family;
  font-size: 10px;
}

body {
  position: relative;
  opacity: 0;
  transition: 0.5s;

  header {
    opacity: 0;
    transition: 0.5s;
    transition-delay: 0.5s;
  }

  main {
    opacity: 0;
    transition: 1s;
    transition-delay: 1s;
  }
}

.docready {
  opacity: 1;

  header {
    opacity: 1;

  }

  main {
    opacity: 1;

  }
}

.site-width {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.container-fluid {
  padding: 0px 5%;
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
}

img {
  width: 100%;
  height: 100%;
}
