@font-face {
  font-family: 'Roboto-Black';
  src: url('../assets/fonts/Roboto-Black.woff') format('woff'),
    url('../assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../assets/fonts/Roboto-Bold.woff') format('woff'),
    url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto-Medium.woff') format('woff'),
    url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff'),
    url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('../assets/fonts/Roboto-Thin.woff') format('woff'),
    url('../assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../assets/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto-Light.woff') format('woff'),
    url('../assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
