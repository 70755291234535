.page {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 5;
}

.page-home {
  background-image: url("../assets/img/png/bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;

  .section-main {
    @include small-phone {
      padding-top: 40px;
    }
  }
}

header {
  z-index: 11;
}

main {
  z-index: 10;
}

.section-main {
  z-index: 10;

  .main-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 80px;
  }

  .menu-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu-link {
    color: $white;
    font-family: $font-regular;
    font-size: 4.4rem;
    padding: 15px 0;
    text-align: center;

    @include desktop-notebook {
      font-size: 4rem;
    }

    &:first-of-type {
      padding-top: 0;
    }
  }
}
